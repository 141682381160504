<template>
	<div>
		<v-simple-table>
			<template v-slot:default>
				<thead>
					<tr>
						<th
							v-for="(item, index) in headers"
							:key="`head-${index}`"
						>
							{{ item.text }}
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(row, indexRow) in payments"
						:key="`body-${indexRow}`"
					>
						<td
							v-for="(col, indexCol) in headers"
							:key="`${indexRow}-col-${indexCol}`"
						>
							<template v-if="col.value == 'attached'">
								<a
									v-if="row.preview.length"
									:href="row.attached"
									target="_blank"
								>
									<v-avatar size="30"
										><v-img
											max-height="50"
											max-width="50"
											:src="row.preview"
										></v-img></v-avatar
								></a>
							</template>
							<template v-else>{{ row[col.value] }}</template>
						</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';

	export default {
		name: 'irrigator-payments-reported-list',

		props: {
			irrigator_code: {
				type: String,
				default: '',
			},
		},

		data() {
			return {
				headers: [
					{ text: '#', value: 'item_number' },
					{ text: 'Código', value: 'code' },
					{ text: 'Numero de comprobante', value: 'payment_number' },
					{ text: 'Comprobante del banco', value: 'bank' },
					{ text: 'Fecha del comprobante', value: 'payment_date' },
					{ text: 'Monto del comprobante', value: 'amount' },
					{ text: 'Recibido', value: 'createdAt' },
					{ text: 'Archivo', value: 'attached' },
				],

				loading: false,
			};
		},

		computed: {
			...mapState('irrigatorControl', ['irrigatorPayments']),

			payments() {
				const list = [],
					irrigatorPayments = this.$utils.deepCopy(
						this.irrigatorPayments
					);
				irrigatorPayments.forEach((item, index) => {
					item.bank = item.bank.name;

					item.item_number = index + 1;

					item.amount = this.$utils.printMoney(item.amount);

					item.payment_date = this.$utils
						.moment(item.payment_date, 'YYYY-MM-DD')
						.format('DD-MM-YYYY');

					item.createdAt = this.$utils
						.moment(item.createdAt)
						.format('DD-MM-YYYY HH:mm');

					item.preview =
						item.attached &&
						item.attached.formats &&
						item.attached.formats.thumbnail
							? process.env.VUE_APP_API +
							  item.attached.formats.thumbnail.url
							: '';

					item.attached = item.attached
						? process.env.VUE_APP_API + item.attached.url
						: '';

					list.push(item);
				});

				return list;
			},
		},

		mounted() {
			const query = { next: 0 };

			/* Si hay definido un codigo hago el ajuste */
			if (this.irrigator_code) {
				this.headers.splice(1, 1);

				query.code = this.irrigator_code;
			}

			this.loadIrrigatorPayments(query);
		},

		methods: {
			...mapActions('irrigatorControl', ['loadIrrigatorPayments']),
		},
	};
</script>
