<template>
	<v-row v-if="irrigatorFeesPaid.length">
		<v-col>
			<v-btn class="primary" :disabled="noMore" @click="loadMore">{{
				buttonText
			}}</v-btn>
		</v-col>
	</v-row>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	export default {
		name: 'irrigator-profile-fees-paid-list-pagination',

		computed: {
			...mapState({
				irrigatorFeesPaid: state =>
					state.irrigatorControl.irrigatorFeesPaid,
				noMore: state => state.irrigatorControl.noMoreIrrigatorFeesPaid,
				lastVisible: state =>
					state.irrigatorControl.irrigatorFeesPaidLastVisible,
			}),

			buttonText() {
				return this.noMore ? 'Fin de la lista' : 'Cargar más';
			},
		},

		methods: {
			...mapActions('irrigatorControl', ['loadIrrigatorFeesPaid']),

			loadMore() {
				this.loadIrrigatorFeesPaid(this.lastVisible);
			},
		},
	};
</script>
