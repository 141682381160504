<template>
	<div>
		<v-row>
			<v-col>
				<v-simple-table v-if="irrigatorsShow.length > 0">
					<template v-slot:default>
						<thead>
							<tr>
								<th
									v-for="(item, index) in headers"
									:key="`head-${index}`"
								>
									{{ item.text }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(row, indexRow) in irrigatorsShow"
								:key="`body-${indexRow}`"
							>
								<td
									v-for="(col, indexCol) in headers"
									:key="`${indexRow}-col-${indexCol}`"
								>
									<a
										@click="readIrrigatorData(row.code)"
										v-if="col.value == 'code'"
									>
										<v-badge
											:color="
												row['slow_payer']
													? 'red accent-4'
													: 'green'
											"
											dot
										>
											{{ row[col.value] }}
										</v-badge>
									</a>
									<span v-else>
										{{ row[col.value] }}
									</span>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
				<div v-else class="no-data">
					&laquo; No hay datos disponibles &raquo;
				</div>
			</v-col>
		</v-row>

		<v-row>
			<v-col class="text-center">
				<IrrigatorListPagination />
			</v-col>
		</v-row>

		<IrrigatorProfile />
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import IrrigatorListPagination from '@/components/IrrigatorListPagination';
	import IrrigatorProfile from '@/components/IrrigatorProfile';

	export default {
		name: 'irrigators-list',

		data() {
			return {
				headers: [
					{ text: '#', value: 'item_number' },
					{ text: 'Código', value: 'code' },
					{ text: 'Nombre', value: 'name' },
					{ text: 'Canal', value: 'channel_name' },
					{ text: 'RUT', value: 'rut' },
					{ text: 'Celador', value: 'watchman_name' },
					{ text: 'Dirección', value: 'address' },
					{ text: 'Acciones', value: 'number_of_actions' },
					{ text: 'Email', value: 'email'},
					{ text: 'Telefono', value: 'phone'},
					// { text: 'Telefono Contacto', value: 'contact_telephone' },
				],
			};
		},

		components: {
			IrrigatorListPagination,
			IrrigatorProfile,
		},

		computed: {
			...mapState({
				irrigators: state => state.irrigatorControl.irrigators,
				irrigatorSelected: state =>
					state.irrigatorControl.irrigatorSelected,
				channels: state => state.irrigatorControl.channels,
			}),

			irrigatorsShow() {
				let irrigators = this.$utils.deepCopy(this.irrigators);

				irrigators.map((irr, index) => {
					irr.item_number = index + 1;

					irr.channel_name = irr.channel ? irr.channel.name : '';

					irr.watchman_name = irr.watchman ? irr.watchman.name : '';

					irr.contact_name = irr.profile
						? irr.profile.contact_name
						: '';
					irr.contact_telephone = irr.profile
						? irr.profile.contact_telephone
						: '';

					return irr;
				});

				return irrigators.length ? irrigators : [];
			},
		},

		methods: {
			...mapActions('irrigatorControl', ['readIrrigatorData']),
		},
	};
</script>

<style lang="scss" scoped>
	.no-data {
		font-size: 1.5rem;
		text-align: center;
		color: #81afd1;
	}
</style>
