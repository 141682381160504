<template>
	<div>
		<v-simple-table>
			<template v-slot:default>
				<thead>
					<tr>
						<th
							v-for="(item, index) in headers"
							:key="`head-${index}`"
						>
							{{ item.text }}
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(row, indexRow) in feesShow"
						:key="`body-${indexRow}`"
					>
						<td
							v-for="(col, indexCol) in headers"
							:key="`${indexRow}-col-${indexCol}`"
						>
							{{ row[col.value] }}
						</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>

		<v-row>
			<v-col class="text-center">
				<IrrigatorProfileFeesPaidListPagination />
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import IrrigatorProfileFeesPaidListPagination from '@/components/IrrigatorProfileFeesPaidListPagination';

	export default {
		name: 'irrigator-profile-fees-paid-list',

		data() {
			return {
				headers: [
					{ text: '#', value: 'item_number' },
					{ text: 'Orden', value: 'order' },
					{ text: 'Año', value: 'year' },
					{ text: 'Vencimiento', value: 'expiration' },
					{ text: 'Procesado', value: 'processed' },
					{ text: 'Monto', value: 'amount_show' },
					{ text: 'Interes', value: 'interests_show' },
					{ text: 'Tipo de cuota', value: 'fees_type' },
				],
				loading: false,
			};
		},

		components: {
			IrrigatorProfileFeesPaidListPagination,
		},

		computed: {
			...mapState({
				irrigatorFeesPaid: state =>
					state.irrigatorControl.irrigatorFeesPaid,
			}),

			feesShow() {
				let fees = this.$utils
					.deepCopy(this.irrigatorFeesPaid)
					.map((fee, index) => {
						fee.item_number = index + 1;

						fee.amount_show = `${fee.coin} ${fee.amount}`;

						fee.interests_show = fee.interests
							? `${fee.coin} ${fee.interests}`
							: `${fee.coin} 0`;

						if (fee.expiration)
							fee.expiration = this.$utils
								.moment(fee.expiration)
								.format('DD/MM/YYYY');

						if (fee.processed)
							fee.processed = this.$utils
								.moment(fee.processed)
								.format('DD/MM/YYYY');

						return fee;
					});

				return fees.length ? fees : [];
			},
		},
	};
</script>
