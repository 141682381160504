<template>
	<div>
		<div v-if="watchman">
			<v-row>
				<v-col>
					<a @click="watchman = null"
						>Volver a la lista de celadores</a
					>
				</v-col>
			</v-row>
			<v-row class="pt-3">
				<v-col cols="5"
					><h2 class="text-h4">
						Lista por rol de regantes de {{ watchman.name }}
					</h2></v-col
				>
				<v-col cols="7">
					<IrrigatorListFilter hide_watchman not_reset />
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12">
					<IrrigatorList />
				</v-col>
			</v-row>
		</div>

		<div v-else>
			<v-row class="pt-3">
				<v-col cols="5"><h2 class="text-h4">Lista celadores</h2></v-col>
			</v-row>

			<v-row>
				<v-col>
					<WatchmanList @watchman:selected="watchmanSelected" />
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
	import WatchmanList from '@/components/WatchmanList';

	import IrrigatorList from '@/components/IrrigatorList';
	import IrrigatorListFilter from '@/components/IrrigatorListFilter';

	import { mapState, mapActions } from 'vuex';

	export default {
		name: 'watchman-page',

		data() {
			return {
				watchman: null,
			};
		},

		components: {
			IrrigatorList,
			IrrigatorListFilter,
			WatchmanList,
		},

		computed: {
			...mapState({
				user: state => state.userControl.user,
			}),
		},

		methods: {
			...mapActions('irrigatorControl', ['updateFilterOptions']),

			watchmanSelected(wat) {
				this.watchman = wat;
				setTimeout(() => {
					this.updateFilterOptions({
						location: null,
						channel: null,
						count: 0,
						next: 0,
						watchman: wat.id,
					});
				}, 500);
			},
		},
	};
</script>
