<template>
    <div>
        <v-col class="btn-download-pdf">
            <v-btn color="primary" @click="download_pdf()">Descargar</v-btn>
        </v-col>

        <div id="my-component" class="pdf-content">
            <div class="pdf-header">
                <div class="pdf-logo">
                    <v-img src="@/assets/logo-acm.png"></v-img>
                </div>
                <div class="pdf-date">
                    <h4> {{ date }}</h4>
                </div>
            </div>
            <div class="pdf-body">
                <div class="pdf-title">
                    <h2>INFORME REGANTE ACM</h2>
                </div>

                <div class="pdf-info">
                    <div class="pdf-info-column">
                        <div class="pdf-info-label">
                            <div class="pdf-info-label-first">
                                <h5>NOMBRE</h5>
                            </div>
                            <div class="pdf-info-label-second">
                                <h5>{{ this.dataIrrigator.name ? this.dataIrrigator.name : 'Sin Nombre' }}</h5>
                            </div>
                        </div>
                        <div class="pdf-info-label">
                            <div class="pdf-info-label-first">
                                <h5>RUT</h5>
                            </div>
                            <div class="pdf-info-label-second">
                                <h5>{{ this.dataIrrigator.rut ? this.dataIrrigator.rut : 'Sin Rut' }}</h5>
                            </div>
                        </div>
                        <div class="pdf-info-label">
                            <div class="pdf-info-label-first">
                                <h5>CELULAR</h5>
                            </div>
                            <div class="pdf-info-label-second">
                                <h5>
                                    {{ this.dataIrrigator.cellphone ? this.dataIrrigator.cellphone : 'Sin Celular'
                                    }}
                                </h5>
                            </div>
                        </div>
                        <div class="pdf-info-label">
                            <div class="pdf-info-label-first">
                                <h5>MAIL</h5>
                            </div>
                            <div class="pdf-info-label-second">
                                <h5>{{ this.dataIrrigator.email ? this.dataIrrigator.email : 'Sin Email' }}</h5>
                            </div>
                        </div>
                        <div class="pdf-info-label">
                            <div class="pdf-info-label-first">
                                <h5>CONVENIO</h5>
                            </div>
                            <div class="pdf-info-label-second">
                                <h5>{{ this.dataIrrigator ? 'Si' : 'No' }}</h5>
                            </div>
                        </div>
                        <div class="pdf-info-label">
                            <div class="pdf-info-label-first">
                                <h5>INSCRIPCIÓN</h5>
                            </div>
                            <div class="pdf-info-label-inscripcion">
                                <div class="pdf-info-inscripcion-detalles">
                                    <h5>Fojas:</h5>
                                    <h5>{{ this.dataIrrigator.inscription_fsj ? this.dataIrrigator.inscription_fsj : ''
                                        }}</h5>
                                </div>
                                <div class="pdf-info-inscripcion-detalles">
                                    <h5>Año :</h5>
                                    <h5>{{ this.dataIrrigator.inscription_year ? this.dataIrrigator.inscription_year :
                                        '' }}</h5>
                                </div>
                                <div class="pdf-info-inscripcion-detalles">
                                    <h5>N°:</h5>
                                    <h5>{{ this.dataIrrigator.inscription_number ? this.dataIrrigator.inscription_number
                                        : '' }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pdf-info-column">
                        <div class="pdf-info-label">
                            <div class="pdf-info-label-first">
                                <h5>CÓDIGO</h5>
                            </div>
                            <div class="pdf-info-label-second">
                                <h5>{{ this.dataIrrigator.code ? this.dataIrrigator.code : ' Sin Código' }}</h5>
                            </div>
                        </div>
                        <div class="pdf-info-label">
                            <div class="pdf-info-label-first">
                                <h5>CANAL</h5>
                            </div>
                            <div class="pdf-info-label-second">
                                <h5>{{ this.dataIrrigator.channel ? this.dataIrrigator.channel : 'Sin Canal' }}</h5>
                            </div>
                        </div>
                        <div class="pdf-info-label">
                            <div class="pdf-info-label-first">
                                <h5>CELADOR</h5>
                            </div>
                            <div class="pdf-info-label-second">
                                <h5>{{ this.dataIrrigator.watchman ? this.dataIrrigator.watchman.name : 'Sin Celador' }}
                                </h5>
                            </div>
                        </div>
                        <div class="pdf-info-label">
                            <div class="pdf-info-label-first">
                                <h5>ACCIONES</h5>
                            </div>
                            <div class="pdf-info-label-second">
                                <h5>{{ this.dataIrrigator.number_of_actions ? this.dataIrrigator.number_of_actions :
                                    'Sin Acciones' }}</h5>
                            </div>
                        </div>
                        <div class="pdf-info-label">
                            <div class="pdf-info-label-first">
                                <h5>DIRECCIÓN</h5>
                            </div>
                            <div class="pdf-info-label-second">
                                <h5>{{ this.dataIrrigator.address ? this.dataIrrigator.address : 'Sin Dirección' }}</h5>
                            </div>
                        </div>
                        <div class="pdf-info-label">
                            <div class="pdf-info-label-first">
                                <h5>ROL PROPIEDAD</h5>
                            </div>
                            <div class="pdf-info-label-inscripcion">
                                <div class="pdf-info-inscripcion-detalles">
                                    <h5>{{ this.dataIrrigator.rol ? this.dataIrrigator.rol : 'Sin Rol Propiedad' }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pdf-section-deudas">
                    <div>
                        <h5>DEUDA</h5>
                    </div>
                    <div class="pdf-deuda" v-if="feesShow.length > 0">
                        <div class="pdf-deuda-label">
                            <div>
                                <h5>AÑO</h5>
                            </div>
                            <div>
                                <h5>TIPO CUOTA</h5>
                            </div>
                            <div>
                                <h5>MONEDA</h5>
                            </div>
                            <div>
                                <h5>MONTO</h5>
                            </div>
                            <div>
                                <h5>MULTA</h5>
                            </div>
                            <div>
                                <h5>VENCIMIENTO</h5>
                            </div>
                        </div>
                        <div class="pdf-deuda-data" v-for="fee in feesShow" :key="fee._id">
                            <div>
                                <h5>{{ fee.year }}</h5>
                            </div>
                            <div>
                                <h5>{{ fee.fees_type }} </h5>
                            </div>
                            <div>
                                <h5>{{ fee.coin }}</h5>
                            </div>
                            <div>
                                <h5>{{ fee.amount }}</h5>
                            </div>
                            <div>
                                <h5>{{ fee.interests_show }}</h5>
                            </div>
                            <div>
                                <h5>{{ fee.expiration }}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="pdf-no-data" v-else>
                        <h3>NO HAY DEUDA EXISTENTE</h3>
                    </div>
                </div>
                <div class="pdf-section-solicitudes">
                    <div>
                        <h5>SOLICITUDES</h5>
                    </div>
                    <div class="pdf-solicitud" v-if="eventList.length > 0">
                        <div class="pdf-solicitud-label">
                            <div>
                                <h5>N° SOLICTUD</h5>
                            </div>
                            <div>
                                <h5>FECHA</h5>
                            </div>
                            <div>
                                <h5>ÁREA</h5>
                            </div>
                            <div>
                                <h5>ASUNTO</h5>
                            </div>
                            <div>
                                <h5>ESTADO</h5>
                            </div>
                        </div>
                        <div class="pdf-solicitud-data" v-for="event in eventList" :key="event.id">
                            <div>
                                <h5>{{ event.item_number }}</h5>
                            </div>
                            <div>
                                <h5>{{ event.created }} </h5>
                            </div>
                            <div>
                                <h5>{{ event.area }}</h5>
                            </div>
                            <div>
                                <h5>{{ event.subject }}</h5>
                            </div>
                            <div>
                                <v-btn fab dark x-small color="green" v-if="event.closed" depressed>
                                    <v-icon dark>mdi-lock-outline</v-icon>
                                </v-btn>

                                <v-btn v-else fab x-small depressed>
                                    <v-icon>mdi-lock-open-variant-outline</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                    <div class="pdf-no-data" v-else>
                        <h3>NO HAY SOLICITUDES</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf';
import logo from '@/assets/logo-acm.png';

export default {
    name: 'irrigator-events-pdf',
    data() {
        return {
            date: new Date().toLocaleDateString('es-ES', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            }),
            feeSelected: [],
            imgLogo: new Image()
        };
    },

    computed: {
        ...mapState({
            irrigatorSelected: state => state.irrigatorControl.irrigatorSelected,
            irrigatorFees: state => state.irrigatorControl.irrigatorFees,
            events: state => state.eventBookControl.events,
        }),
        ...mapState('eventBookControl', ['events', 'eventTypes']),
        feesShow() {
            let fees = this.$utils
                .deepCopy(this.irrigatorFees)
                .map((fee, index) => {
                    fee.item_number = index + 1;

                    fee.amount_show = `${fee.coin} ${fee.amount}`;

                    fee.interests_show = fee.interests
                        ? `${fee.coin} ${fee.interests}`
                        : `${fee.coin} 0`;

                    if (fee.expiration)
                        fee.expiration = this.$utils
                            .moment(fee.expiration)
                            .format('DD/MM/YYYY');

                    return fee;
                });

            return fees.length ? fees : [];
        },
        dataIrrigator() {
            const irrigator = this.$utils.deepCopy(this.irrigatorSelected);

            if (irrigator.channel)
                irrigator.channel = `${irrigator.channel.name}`;
            if (irrigator.location)
                irrigator.location = irrigator.location.name;

            if (irrigator.others && irrigator.others.length) {
                irrigator.others = irrigator.others.map(item => {
                    const irr = {
                        code: item.code,
                        name: item.code,
                    };

                    /* Señala el usuario para iniciar sesion si lo hay */
                    if (irrigator.user && irrigator.user.code == item.code)
                        irr.name += ' [Inicio de sesión]';

                    return irr;
                });
            }

            return irrigator;
        },
        eventList() {
            return this.events.map((item, index) => {
                return {
                    item_number: index + 1,
                    type: this.eventTypes.reduce(
                        (acc, curr) =>
                            curr.type == item.type ? curr.name : acc,
                        ''
                    ),
                    area: item.association_area
                        ? item.association_area.name
                        : '',
                    subject: item.subject,
                    class: this.eventTypes.reduce(
                        (acc, curr) =>
                            curr.type == item.type ? curr.class : acc,
                        ''
                    ),
                    created: this.$utils
                        .moment(item.createdAt)
                        .format('DD/MM/YYYY HH:mm'),
                    closed: item.closed,

                    status: '',

                    id: item.id,

                    fulldata: item,

                    irrigator: item.irrigator
                        ? `Regante: ${item.irrigator.name} (${item.irrigator.code})`
                        : null,

                    channel: item.channel
                        ? `${item.channel.name} (${item.channel.code})`
                        : null,
                };
            });
        },
    },
    methods: {
        ...mapActions('eventBookControl', [
            'loadEvents', 'resetEventsLastVisible'
        ]),
        download_pdf() {
            this.resetEventsLastVisible();
            const footer = "3 1/2 Sur 15 y 16 Oriente N° 2250, Talca - Fonos:71 2266 439 - 71 2260 002 - E-Mail: info@canalmaule.cl";
            const note = "* Este documento es una herramienta de apoyo para el análisis interno y no debe ser considerado como una fuente definitiva de información.\n   Los datos presentados deben interpretarse en el contexto de las variables y supuestos utilizados en su generación."

            return new Promise((resolve) => {

                this.loadEvents({
                    next: 0,
                    count: 0,
                    irrigatorCode: this.dataIrrigator.code,
                    type: 'requestforattention'
                }).then(() => {
                    this.loadEvents({
                        next: 0,
                        count: 0,
                        irrigatorCode: this.dataIrrigator.code,
                        type: 'annotation'
                    }).then(() => {
                        html2canvas(document.getElementById('my-component'),
                            {
                                scale: 3
                            }).then(canvas => {
                                const imgData = canvas.toDataURL('image/png', 1);
                                const pdf = new jsPDF({
                                    unit: 'px',
                                    format: ['2000', '1600']
                                });
                                pdf.addImage(imgData, 'PNG', 10, 10);


                                // Agrega el Logo
                                pdf.addImage(this.imgLogo, 'PNG', 10, 10, 300, 180); // Ajusta las dimensiones según sea necesario

                                // Ancho de la página
                                const pageWidth = pdf.internal.pageSize.getWidth();

                                // Ancho del text
                                const textWidth = pdf.getTextWidth(footer);
                                const noteWidth = pdf.getTextWidth(note);

                                // Calcular posición X para centrar el texto
                                const x = (pageWidth - textWidth) / 4;
                                const noteX = (pageWidth - noteWidth) / 4;

                                // Posición Y para el texto
                                const y = pdf.internal.pageSize.getHeight() - 20;
                                const noteY = pdf.internal.pageSize.getHeight() - 100;


                                //Agregar nota informativa
                                pdf.setTextColor(1, 45, 114)
                                pdf.setFontSize(30)
                                pdf.text(note, noteX, noteY)

                                // Dibujar una línea horizontal sobre el texto
                                pdf.setDrawColor(1, 45, 114);  // Color de la línea
                                pdf.setLineWidth(4);  // Grosor de la línea
                                pdf.line(25, y - 35, pageWidth - 25, y - 35);  // Línea de borde a borde

                                // Agregar el pie de página a cada página
                                pdf.setTextColor(1, 45, 114);
                                pdf.setFontSize(30);
                                pdf.text(footer, x, y);

                                pdf.save('my_pdf.pdf');
                            });
                        resolve();
                    });
                });
            });
            // Function for Set Footer
            // for (let i = 1; i <= totalPages; i++) {
            // 	doc.setPage(i);
            // }
        }
    },
    mounted() {
        // Función auxiliar para cargar la imagen
        const loadImage = (src) => {
            return new Promise(resolve => {
                this.imgLogo.onload = () => resolve(this.imgLogo);
                this.imgLogo.src = src;
            });
        };
        loadImage(logo)
    }
}
</script>

<style>
@font-face {
    font-family: 'FuentePDF';
    src: url('../assets/fonts/gothic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

:root {
    --azulOscuro: #012D72;
    --celeste: #218DB1;
    --azul: #2076BD;
    --blanco: #E8E8E8;
    --gris: #B4BBCF;
}

.btn-download-pdf {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
}

.pdf-content {
    font-family: 'FuentePDF';
    margin: 1em;
    padding: 1em 0;
    width: 900px;
    position: fixed;
    left: -9999px;
}

.pdf-content h1,
.pdf-content h2,
.pdf-content h3,
.pdf-content h4,
.pdf-content h5 {
    color: var(--azulOscuro);
}

.pdf-header {
    display: flex;
    justify-content: space-between;
}

.pdf-body {
    min-height: 500px;
    margin: 0 100px;
}

.pdf-logo {
    width: 150px;
    height: 100px;
}

.pdf-date {
    display: flex;
    align-items: flex-end;
    margin: 1em;
}

.pdf-date h5 {
    font-weight: 600;
}

.pdf-title {
    text-align: center;
    padding: 2em;
}

.pdf-title h2 {
    font-weight: 900;
}

.pdf-info {
    display: flex;
    justify-content: space-between;
    margin: 0.5em 0;
}

.pdf-info h5 {
    font-weight: 700;
}

.pdf-info-column {
    margin: 1em 0;
    width: 50%;
}


/** PDF INFO CSS STYLE */

.pdf-info-label {
    display: flex;
}

.pdf-info-label-first {
    width: 30%;
    padding: 5px 0;
}

.pdf-info-label-first h5,
.pdf-info-inscripcion-detalles h5:first-child {
    font-weight: 900;
}

.pdf-info-label-second {
    width: 70%;
    padding: 5px 0;
}

/* .pdf-info-label-second h5,
.pdf-info-inscripcion-detalles h5:last-child {
	color: var(--celeste)
} */

.pdf-info-label-inscripcion {
    width: 70%;
    padding: 5px 0;
    display: flex;
    gap: 10px
}

.pdf-info-label-inscripcion div {
    display: flex;
    gap: 10px;
}

/* PDF Tables CSS STYLE */

.pdf-deuda {
    margin-bottom: 3em;
}

.pdf-deuda-label,
.pdf-deuda-data {
    display: flex;
}

.pdf-no-data {
    border: 1px solid;
    padding: 1em;
    text-align: center;
    margin-bottom: 2em;
}

.pdf-deuda-label div {
    width: 100%;
    border: 1px solid;
    text-align: center;
    padding: 1em 0;
    margin-top: 5px;
}

.pdf-deuda-data div {
    width: 100%;
    border: 1px solid;
    text-align: center;
    padding: 3px 0;
}

.pdf-deuda-label h5:first-child {
    font-weight: 900;
}

.pdf-deuda-data h5 {
    font-weight: 500;
}

/* PDF SOLICITUDES CSS STYLE */

.pdf-solicitud-label,
.pdf-solicitud-data {
    display: flex;
}

.pdf-solicitud {
    width: 85%;
}

.pdf-solicitud-label div {
    width: 100%;
    padding: 1em;
    border: 1px solid;
    text-align: center;
    margin-top: 5px;
}

.pdf-solicitud-data div {
    width: 100%;
    border: 1px solid;
    text-align: center;
    padding: 3px 0;
}

.pdf-solicitud-label h5:first-child {
    font-weight: 900;
}

.pdf-solicitud-data h5 {
    font-weight: 500;
}


/* PDF NOTE CSS */

.pdf-note {
    text-align-last: justify;
    margin-top: 10em;
}


/* Footer CSS */

.pdf-footer {
    text-align: center;
    color: var(--azul);
}

.pdf-footer p {
    font-weight: 300;
}
</style>