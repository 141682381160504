<template>
	<div>
		<v-simple-table>
			<template v-slot:default>
				<thead>
					<tr>
						<th v-for="(item, index) in headers" :key="`head-${index}`">
							{{ item.text }}
						</th>
					</tr>
				</thead>
				<tbody id='my-html-element'>
					<tr v-for="(row, indexRow) in feesShow" :key="`body-${indexRow}`">
						<td v-for="(col, indexCol) in headers" :key="`${indexRow}-col-${indexCol}`">
							{{ row[col.value] }}
						</td>

					</tr>
				</tbody>
			</template>
		</v-simple-table>

		<v-row>
			<v-col class="text-center">
				<IrrigatorProfileFeesListPagination />
			</v-col>
		</v-row>



	</div>
</template>

<script>
import { mapState } from 'vuex';
import IrrigatorProfileFeesListPagination from '@/components/IrrigatorProfileFeesListPagination';

export default {
	name: 'irrigator-profile-fees-list',

	data() {
		return {
			headers: [
				{ text: '#', value: 'item_number' },
				{ text: 'Año', value: 'year' },
				{ text: 'Vencimiento', value: 'expiration' },
				{ text: 'Monto', value: 'amount_show' },
				{ text: 'Interes', value: 'interests_show' },
				{ text: 'Tipo de cuota', value: 'fees_type' },
			],
			loading: false,
		};
	},

	components: {
		IrrigatorProfileFeesListPagination,
	},

	computed: {
		...mapState({
			irrigatorFees: state => state.irrigatorControl.irrigatorFees,
		}),

		feesShow() {
			let fees = this.$utils
				.deepCopy(this.irrigatorFees)
				.map((fee, index) => {
					fee.item_number = index + 1;

					fee.amount_show = `${fee.coin} ${fee.amount}`;

					fee.interests_show = fee.interests
						? `${fee.coin} ${fee.interests}`
						: `${fee.coin} 0`;

					if (fee.expiration)
						fee.expiration = this.$utils
							.moment(fee.expiration)
							.format('DD/MM/YYYY');

					return fee;
				});

			return fees.length ? fees : [];
		},
	},

}
</script>
