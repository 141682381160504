<template>
	<div>
		<ModalConfirmation ref="confirm"></ModalConfirmation>
		<v-simple-table>
			<template v-slot:default>
				<thead>
					<tr>
						<th>Nombre de Archivo</th>
						<th>Tipo de Archivo</th>
						<th>Tamaño</th>
						<th>Descargar</th> <!-- Añadir cabecera para la columna de descarga -->
						<th>Opciones</th>
					</tr>
				</thead>
				<tbody>
					<!-- <div v-for="(item) in file" :key="item.id">
					<h2>{{item.file[0].name}}</h2>
				</div> -->
					<tr v-for="(item) in file" :key="item.id">
						<td>{{ item.file[0].name }}</td>
						<td>{{ item.file[0].ext }}</td>
						<td>{{ item.file[0].size }}</td>
						<td>
							<!-- Añadir enlace de descarga -->
							<v-btn depressed fab x-small color="primary" @click="download(item.file[0].url)">
								<v-icon>mdi-download-outline</v-icon>
							</v-btn>
						</td>
						<td><button @click="deleteConfirmFile(item.id)">Eliminar</button></td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>

		<v-row>
			<v-col class="text-center">
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-file-input accept=".pdf," v-model="fileToUpload" truncate-length="15"></v-file-input>
				<v-btn class="primary" @click="uploadFile">
					Cargar
				</v-btn>
			</v-col>
		</v-row>

		<Alert ref="success"></Alert>

	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ModalConfirmation from '@/components/ModalConfirmation.vue';
import Alert from '@/components/Alert.vue';
export default {
	name: 'irrigator-profile-files',

	data() {
		return {
			file: [], // Usado para almacenar los archivos cargados
			fileToUpload: null,
		};
	},
	mounted() {
		this.fetchFilesByCode(this.irrigatorProfile.code)

	},
	computed: {
		...mapState('irrigatorControl', ['irrigatorProfile']),
	},
	components: {
		ModalConfirmation,
		Alert
	},
	methods: {
		...mapActions('filesControl', ['uploadIrrigatorFile', 'fetchFilesByCode']),


		handleFileChange(event) {
			const file = event.target.files[0];
			if (!file) return; // Si no hay archivo, no hacer nada

			this.fileToUpload = file; // Guardar el archivo para subirlo

			this.uploadFile(); // Llamar a uploadFile directamente
		},

		async deleteConfirmFile(code) {
			if (await this.$refs.confirm.open('Confirmar', '¿Seguro que desea eliminar?', { color: '#cc1515' })) {
				this.deleteFile(code)
			}
			else {
				console.log("Operación cancelada")
			}
		},
		uploadFile() {
			if (this.fileToUpload) {
				// Construir el objeto con el archivo, nombre, y código
				const fileInfo = {
					file: this.fileToUpload,
					name: this.fileToUpload.name, // Extraer el nombre del archivo seleccionado
					code: this.irrigatorProfile.code, // Código fijo por ahora "0203-0455"
				};
				this.uploadIrrigatorFile(fileInfo).finally(() => {
					this.fileToUpload = null;
					this.fetchFilesByCode(this.irrigatorProfile.code)
					this.$refs.success.open('Archivo subido correctamente', 'success')
				});
			}
		},
		deleteFile(code) {
			this.$store.dispatch('filesControl/deleteIrrigatorFile', code).finally(() => {
				this.fetchFilesByCode(this.irrigatorProfile.code)
			})
		},
		fetchFilesByCode(code) {
			this.$store.dispatch('filesControl/fetchFilesByCode', code)
				.then(response => {
					// Asegúrate de manejar y asignar la respuesta adecuadamente aquí
					// Posiblemente necesites ajustar esta parte basada en la estructura real de tu respuesta
					this.file = response; // Asegúrate de que esto corresponde a la estructura de tu respuesta
					console.log("Archivo cargado:", this.file);
					console.log("USER: ", this.irrigatorProfile.code);
					// if (this.file && this.file[0].file && this.file.length > 0) {
					// console.log("URL del archivo:", this.file.file[0].url);
					// }		
				})
				.catch(error => {
					console.error('Error al cargar archivos:', error);
				});
		},
		metadata() {
			// Asegurarse de que file está definido antes de acceder a sus propiedades.
			if (!this.file) {
				return {}; // Retorna un objeto vacío si file aún no está definido.
			}

			let data = { ...this.file.file[0] }; // Asume que file es un objeto que contiene un objeto file interno.
			data.size = this.$utils.formatBytes(this.data.size * 1000);
			data.url = process.env.VUE_APP_API + this.data.url;
			return data;
		},
		download(url) {
			url = process.env.VUE_APP_API + url;
			console.log(url);
			window.open(url, '_blank');
		},

	}
};
</script>

<style>
.file-box {
	border: 1px solid #ccc;
	padding: 10px;
	margin-bottom: 20px;
}
</style>