<template>
	<v-row v-if="irrigatorFees.length">
		<v-col>
			<v-btn class="primary" :disabled="noMore" @click="loadMore">{{
				buttonText
			}}</v-btn>
		</v-col>
	</v-row>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	export default {
		name: 'irrigator-profile-fees-list-pagination',

		computed: {
			...mapState({
				irrigatorFees: state => state.irrigatorControl.irrigatorFees,
				noMore: state => state.irrigatorControl.noMoreIrrigatorFees,
				lastVisible: state =>
					state.irrigatorControl.irrigatorFeesLastVisible,
			}),

			buttonText() {
				return this.noMore ? 'Fin de la lista' : 'Cargar más';
			},
		},

		methods: {
			...mapActions('irrigatorControl', ['loadIrrigatorFees']),

			loadMore() {
				this.loadIrrigatorFees(this.lastVisible);
			},
		},
	};
</script>
