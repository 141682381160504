<template>
    <div v-if="showNotification">
        <v-alert text="" title="Alert title" :type=type class="box-alert">{{ message }}</v-alert>
    </div>
</template>


<script>
export default {
    data() {
        return {
            showNotification: false,
            message: null,
            type: null
        }
    },
    methods: {
        open(message, type) {
            this.message = message
            this.type = type
            setTimeout(() => {
                this.showNotification = true;
            }, 500)
            setTimeout(() => {
                this.showNotification = false;
            }, 3000);
        }
    }
}
</script>

<style lang="scss" scoped>
.box-alert {
    position: fixed;
    bottom: 45px;
    right: 40px;
    font-weight: 500;
    animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    95% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
</style>
