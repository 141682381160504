<template>
	<v-dialog :value="irrigatorProfile.code ? true : false" fullscreen hide-overlay
		transition="dialog-bottom-transition">
		<v-card color="blue lighten-5">
			<v-toolbar dark color="primary">
				<v-btn icon dark @click="close">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title>Información del Regante</v-toolbar-title>
			</v-toolbar>

			<v-card-text>
				<v-row>
					<v-col class="pt-8" cols="12">
						<v-card>
							<v-card-text>
								<v-row>
									<v-col cols="5">
										<div class="avatar-name">
											<div class="grid-avatar">
												<v-avatar color="primary" size="128">
													<v-img v-if="
														profileData.picture
													" :src="profileData.picture
														">
													</v-img>
													<v-icon v-else dark>
														mdi-camera-outline
													</v-icon>
												</v-avatar>
											</div>

											<div class="grid-name">
												<h1 class="text-h5">
													{{ dataIrrigator.name }} -
													{{ dataIrrigator.code }}
												</h1>

												<h2 class="text-h6">
													Celador: {{ watchman }}
												</h2>

												<v-btn color="primary" class="mt-4" @click="toggleEdit" outlined>Editar
													perfil</v-btn>
											</div>
										</div>
									</v-col>

									<v-col cols="4" v-if="edit">
										<form class="text-center">
											<v-text-field label="Representante legal" hide-details="auto" v-model="profileData.contact_name
												"></v-text-field>

											<v-text-field label="Número de contacto principal" hide-details="auto"
												v-model="profileData.contact_telephone
													"></v-text-field>

											<v-text-field label="Dirección principal" hide-details="auto" v-model="profileData.contact_address
												"></v-text-field>

											<v-text-field label="Número de contacto alternativo" hide-details="auto"
												v-model="profileData.contact_telephone_2nd
													"></v-text-field>

											<v-text-field label="Dirección de correspondencia" hide-details="auto"
												v-model="profileData.contact_address_2nd
													"></v-text-field>

											<v-switch v-if="profileData.coordinates" v-model="deleteMap" :label="deleteMap
												? `Limpiar coordenadas`
												: `Mantener coordenadas`
												"></v-switch>

											<v-text-field label="Correo electrónico" hide-details="auto"
												v-model="profileData.email" type="email"></v-text-field>

											<v-text-field :label="dataIrrigator.user
												? `Actualizar contraseña`
												: `Crear contraseña`
												" hide-details="auto" v-model="profileData.password" type="password"></v-text-field>

											<p class="
													text-left
													font-weight-bold
												" v-if="!dataIrrigator.user">
												<v-icon>mdi-alert-outline</v-icon>
												Este regante no cuenta con
												acceso al sistema aún. Cree la
												contraseña y presione el botón
												para registrarla. Debes enviarle
												al regante la contraseña que
												acabas de crear junto con su
												código de regante.
											</p>

											<v-btn class="mt-6 mr-2" color="primary" @click="toggleEdit"
												outlined>Cancelar</v-btn>

											<v-btn class="mt-6 ml-2" :loading="loading" color="primary" @click="updateIrrigatorProfileForm
												">Actualizar</v-btn>
										</form>
									</v-col>

									<v-col cols="4" v-else>
										<v-row>
											<v-col cols="6">
												<v-list two-line>
													<v-list-item>
														<v-list-item-avatar size="32px">
															<v-icon class="
																	grey
																	lighten-1
																" dark small>
																mdi-account-outline
															</v-icon>
														</v-list-item-avatar>

														<v-list-item-content>
															<v-list-item-title mb="0" v-text="`Nombre`
																"></v-list-item-title>
															<v-list-item-subtitle v-text="profileData.contact_name
																"></v-list-item-subtitle>
														</v-list-item-content>
													</v-list-item>

													<v-list-item>
														<v-list-item-avatar size="32px">
															<v-icon class="
																	grey
																	lighten-1
																" dark small>
																mdi-home-outline
															</v-icon>
														</v-list-item-avatar>

														<v-list-item-content>
															<v-list-item-title mb="0" v-text="`Dirección`
																"></v-list-item-title>
															<v-list-item-subtitle v-text="profileData.contact_address
																"></v-list-item-subtitle>
														</v-list-item-content>
													</v-list-item>

													<v-list-item>
														<v-list-item-avatar size="32px">
															<v-icon class="
																	grey
																	lighten-1
																" dark small>
																mdi-phone-outline
															</v-icon>
														</v-list-item-avatar>

														<v-list-item-content>
															<v-list-item-title mb="0" v-text="`Teléfono`
																"></v-list-item-title>
															<v-list-item-subtitle v-text="profileData.contact_telephone
																"></v-list-item-subtitle>
														</v-list-item-content>
													</v-list-item>
												</v-list>
											</v-col>
											<v-col cols="6">
												<v-list two-line>
													<v-list-item>
														<v-list-item-avatar size="32px">
															<v-icon class="
																	grey
																	lighten-1
																" dark small>
																mdi-email-outline
															</v-icon>
														</v-list-item-avatar>

														<v-list-item-content>
															<v-list-item-title mb="0"
																v-text="`Email`"></v-list-item-title>
															<v-list-item-subtitle v-text="profileData.email
																"></v-list-item-subtitle>
														</v-list-item-content>
													</v-list-item>

													<v-list-item>
														<v-list-item-avatar size="32px">
															<v-icon class="
																	grey
																	lighten-1
																" dark small>
																mdi-home-outline
															</v-icon>
														</v-list-item-avatar>

														<v-list-item-content>
															<v-list-item-title mb="0" v-text="`Dirección 2`
																"></v-list-item-title>
															<v-list-item-subtitle v-text="profileData.contact_address2nd
																"></v-list-item-subtitle>
														</v-list-item-content>
													</v-list-item>

													<v-list-item>
														<v-list-item-avatar size="32px">
															<v-icon class="
																	grey
																	lighten-1
																" dark small>
																mdi-phone-outline
															</v-icon>
														</v-list-item-avatar>

														<v-list-item-content>
															<v-list-item-title mb="0" v-text="`Teléfono 2`
																"></v-list-item-title>
															<v-list-item-subtitle v-text="profileData.contact_telephone2nd
																"></v-list-item-subtitle>
														</v-list-item-content>
													</v-list-item>
												</v-list>
											</v-col>
										</v-row>
									</v-col>

									<v-col cols="3">
										<a v-if="profileData.coordinates" target="_blank"
											:href="`https://maps.google.com/maps?q=${profileData.coordinates.latitude}%2C${profileData.coordinates.longitude}&z=17&hl=es`">Ver
											mapa</a>

										<OpenMap v-if="
											profileData.coordinates && show
										" :coord="center" />
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>

					<v-col class="pt-8" cols="3">
						<v-card>
							<v-card-title primary-title>
								{{ dataIrrigator.name }}
							</v-card-title>
							<v-card-text class="from-association">
								<v-row>
									<v-col cols="12" v-if="
										showElement &&
										dataIrrigator.others &&
										dataIrrigator.others.length > 1
									">
										<v-select v-model="irrigator_code" :items="dataIrrigator.others"
											label="Códigos asociados" item-text="name" item-value="code"
											@change="changeIrrigator" solo></v-select>
									</v-col>

									<v-col cols="6">
										<span>Código de regante</span>
										<span>{{ dataIrrigator.code }}</span>
									</v-col>

									<v-col cols="6">
										<span>Dirección</span>
										<span>{{ dataIrrigator.address }}</span>
									</v-col>

									<v-col v-if="dataIrrigator.email">
										<span>Mail</span>
										<span>{{ dataIrrigator.email }}</span>
									</v-col>
									<v-col v-if="dataIrrigator.phone">
										<span>Teléfono</span>
										<span>{{ dataIrrigator.phone }}</span>
									</v-col>

									<v-col cols="6">
										<span>Inscripción</span>
										<span>Fsj:{{
											dataIrrigator.inscription_fsj
										}}
											N:{{
												dataIrrigator.inscription_number
											}}
											Año:
											{{
												dataIrrigator.inscription_year
											}}</span></v-col>

									<v-col cols="6">
										<span>R.O.L.</span>
										<span>{{
											dataIrrigator.rol
										}}</span></v-col>

									<v-col cols="6">
										<span>RUT</span>
										<span>{{
											dataIrrigator.rut
										}}</span></v-col>

									<v-col cols="6">
										<span>Localidad</span>
										<span>{{
											dataIrrigator.location
										}}</span>
									</v-col>

									<v-col cols="6">
										<span>Canal</span>
										<span>{{ dataIrrigator.channel }}</span>
									</v-col>

									<v-col cols="6">
										<span>Observación</span>
										<span>{{
											dataIrrigator.observation
										}}</span>
									</v-col>

									<v-col cols="6">
										<span>Estado</span>
										<span>{{ dataIrrigator.status }}</span>
									</v-col>

									<v-col cols="6">
										<span>N° acciones</span>
										<span>{{
											dataIrrigator.number_of_actions
										}}</span>
									</v-col>

									<v-col cols="6">
										<span>Convenio</span>
										<span>{{
											dataIrrigator.agreement
										}}</span>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>

					<v-col class="pt-8">
						<v-row class="mb-2" v-if="showElement">
							<v-col cols="2">
								<v-btn block color="primary" :outlined="showCard != 'events'"
									@click="showCard = 'events'">Eventos</v-btn>
							</v-col>
							<v-col cols="2">
								<v-btn block color="primary" :outlined="showCard != 'fees'"
									@click="showCard = 'fees'">Deuda</v-btn>
							</v-col>
							<v-col cols="2">
								<v-btn block color="primary" :outlined="showCard != 'feespaid'"
									@click="showCard = 'feespaid'">Pagos históricos</v-btn>
							</v-col>
							<v-col cols="2">
								<v-btn block color="primary" :outlined="showCard != 'payments'"
									@click="showCard = 'payments'">Pagos recibidos</v-btn>
							</v-col>
							<v-col cols="2">
								<v-btn block color="primary" :outlined="showCard != 'eventHistory'"
									@click="showCard = 'eventHistory'">Historial Eventos</v-btn>
							</v-col>
							<v-col cols="2">
								<v-btn block color="primary" :outlined="showCard != 'domainFile'"
									@click="showCard = 'domainFile'">Dominio Vigente</v-btn>
							</v-col>
						</v-row>

						<v-card v-if="showCard == 'fees'">
							<v-card-text>
								<h2>
									Cuotas
									<span class="text-subtitle-2">- Actualizado:
										{{ dateLastUpdate }}</span>
								</h2>
								<IrrigatorEventsPDF v-if="irrigatorFees.length > 0" />
								<IrrigatorProfileFeesList v-if="dataIrrigator.code" />
							</v-card-text>
						</v-card>

						<v-card v-if="showCard == 'feespaid'">
							<v-card-text>
								<template v-if="dataIrrigator.code">
									<h2 class="mt-8">
										Lista de pagos historicos cargados desde
										administración
									</h2>
									<IrrigatorProfileFeesPaidList v-if="dataIrrigator.code" />
								</template>
							</v-card-text>
						</v-card>

						<v-card v-if="showCard == 'payments'">
							<v-card-text>
								<template v-if="dataIrrigator.code">
									<h2 class="mt-8">
										Pagos reportados desde el sistema
									</h2>
									<IrrigatorPaymentsReportedList :irrigator_code="dataIrrigator.code" />
								</template>
							</v-card-text>
						</v-card>

						<v-card v-if="showCard == 'events'">
							<v-card-text>
								<IrrigatorProfileEventsList v-if="dataIrrigator.code" :style="selectedEvent.id ? `display: none;` : ''
									" :allEvents="false" />
								<EventChat heightList="60vh" v-if="selectedEvent.id" />
							</v-card-text>
						</v-card>

						<v-card v-if="showCard == 'eventHistory'">
							<v-card-text>
								<IrrigatorProfileAllEventsList v-if="dataIrrigator.code" :style="selectedEvent.id ? `display: none;` : ''
									" />
								<EventChat heightList="60vh" v-if="selectedEvent.id" />
							</v-card-text>
						</v-card>
						<v-card v-if="showCard == 'domainFile'">
							<v-card-text>
								<template v-if="dataIrrigator.code">
									<h2 class="mt-8">
										Dominio Vigente
									</h2>
									<IrrigatorProfileFile :irrigator_code="dataIrrigator.code" />
								</template>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
/* Abre el perfil del regante, aprovechando los datos cargados en la lista 
para evitar mas consumo de base de datos */
import { mapActions, mapState } from 'vuex';
import IrrigatorProfileFeesList from '@/components/IrrigatorProfileFeesList';
import IrrigatorProfileFeesPaidList from '@/components/IrrigatorProfileFeesPaidList';
import IrrigatorPaymentsReportedList from '@/components/IrrigatorPaymentsReportedList';
import IrrigatorProfileFile from '@/components/IrrigatorProfileFile';
import EventChat from '@/components/EventChat';

import OpenMap from '@/components/OpenMap';

import IrrigatorProfileEventsList from '@/components/IrrigatorProfileEventsList';
import IrrigatorProfileAllEventsList from '@/components/IrrigatorProfileAllEventsList';
import IrrigatorEventsPDF from '@/components/IrrigatorEventsPDF.vue';

export default {
	name: 'irrigator-profile',

	data() {
		return {
			profileData: {
				contact_name:
					'' /* Para los usuario del departamento legal */,
				contact_telephone:
					'' /* Para los usuario del departamento legal */,
				contact_address: '' /* Para el envio de correspondencia */,
				contact_telephone_2nd: '',
				contact_address_2nd: '',
				email: '' /* Para capturar el correo del regante */,
				app_setting: {},
			},

			deleteMap: false,

			showCard: 'events', // fees, payments, events

			irrigator_code: '',

			loading: false,

			edit: false,

			show: false,
		};
	},

	components: {
		IrrigatorProfileFeesList,
		IrrigatorProfileFeesPaidList,
		IrrigatorPaymentsReportedList,
		IrrigatorProfileEventsList,
		IrrigatorProfileAllEventsList,
		IrrigatorProfileFile,
		IrrigatorEventsPDF,
		EventChat,
		OpenMap,
	},

	watch: {
		irrigatorProfile(value) {
			this.show = false;
			this.profileData = { ...this.profileData, ...value };

			/* Codigo de regante para el select que se usa para cambiar de regante */
			this.irrigator_code = value.code;

			delete this.profileData.id;
			if (this.profileData.coordinates) this.mapOpen();
		},
	},

	computed: {
		...mapState({
			selectedEvent: state => state.eventBookControl.selectedEvent,
            irrigatorFees: state => state.irrigatorControl.irrigatorFees,
			/* Para conocer el rol del usuario */
			user: state => state.userControl.user,

			irrigatorProfile: state =>
				state.irrigatorControl.irrigatorProfile,
			irrigatorSelected: state =>
				state.irrigatorControl.irrigatorSelected,

			/* Ultima actualizacion de cuotas deuda */
			lastUpdateFees: state => state.filesControl.lastUpdateFees,
		}),

		dateLastUpdate() {
			return this.$utils
				.moment(this.lastUpdateFees.createdAt)
				.format('D MMM YYYY HH:mm:ss');
		},

		dataIrrigator() {
			const irrigator = this.$utils.deepCopy(this.irrigatorSelected);

			if (irrigator.channel)
				irrigator.channel = `${irrigator.channel.code} - ${irrigator.channel.name}`;
			if (irrigator.location)
				irrigator.location = irrigator.location.name;

			if (irrigator.others && irrigator.others.length) {
				irrigator.others = irrigator.others.map(item => {
					const irr = {
						code: item.code,
						name: item.code,
					};

					/* Señala el usuario para iniciar sesion si lo hay */
					if (irrigator.user && irrigator.user.code == item.code)
						irr.name += ' [Inicio de sesión]';

					return irr;
				});
			}

			return irrigator;
		},

		/* Coordenadas para el mapa */
		center() {
			return {
				lat: this.profileData.coordinates
					? this.profileData.coordinates.latitude
					: 0,
				lng: this.profileData.coordinates
					? this.profileData.coordinates.longitude
					: 0,
			};
		},

		/* Mostrar elementos solo a los administradores */
		showElement() {
			return (
				this.user &&
				this.user.association_area &&
				!['watchman', 'sectionm'].includes(
					this.user.association_area.code
				)
			);
		},

		watchman() {
			return this.irrigatorSelected.watchman
				? this.irrigatorSelected.watchman.name
				: 'Sin celador asignado';
		},
	},

	mounted() {
		this.loadLastUpdateFees();
	},

	methods: {
		...mapActions('irrigatorControl', [
			'unreadIrrigatorData',
			'updateIrrigatorProfile',
			'readIrrigatorData',
		]),
		...mapActions('eventBookControl', ['unselectEvent', 'resetEvents']),
		...mapActions('filesControl', ['loadLastUpdateFees']),

		changeIrrigator(code) {
			this.close();
			this.readIrrigatorData(code);
		},

		updateIrrigatorProfileForm() {
			/* Se elimina las coordenadas */
			if (this.deleteMap) this.profileData.coordinates = null;

			let profile = this.$utils.deepCopy(this.profileData);
			this.loading = true;

			this.updateIrrigatorProfile(profile).finally(() =>
				this.toggleEdit()
			);
		},

		toggleEdit() {
			this.profileData = {
				contact_name:
					'' /* Para los usuario del departamento legal */,
				contact_telephone:
					'' /* Para los usuario del departamento legal */,
				contact_address: '' /* Para el envio de correspondencia */,
				contact_telephone_2nd: '',
				contact_address_2nd: '',
				email: '' /* Para capturar el correo del regante */,
				app_setting: {},
			};

			this.profileData = {
				...this.profileData,
				...this.irrigatorProfile,
			};

			delete this.profileData.id;

			this.edit = !this.edit;
			this.loading = false;
		},

		close() {
			this.unselectEvent();
			this.unreadIrrigatorData();
			this.resetEvents();

			this.profileData = {
				contact_name:
					'' /* Para los usuario del departamento legal */,
				contact_telephone:
					'' /* Para los usuario del departamento legal */,
				contact_address: '' /* Para el envio de correspondencia */,
				email: '' /* Para capturar el correo del regante */,
				app_setting: {},
			};
			this.irrigator_code = '';
			this.loading = false;
		},

		mapOpen() {
			setInterval(() => (this.show = true), 500);
		},
	},
};
</script>

<style lang="scss" scoped>
.avatar-name {
	display: grid;
	grid-template-columns: 150px 1fr;
	grid-template-rows: auto auto;
	grid-gap: 0rem;
	grid-template-areas: 'avatar	name';

	.grid-avatar {
		grid-area: avatar;
	}

	.grid-name {
		grid-area: name;
	}
}

.from-association {
	>div {
		span:first-child {
			font-weight: 700;
			display: block;
		}

		span:last-child {
			display: block;
		}
	}
}
</style>
