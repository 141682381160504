<template>
	<div>
		<h2>Lista de eventos del regante</h2>
		<EventListFilter class="mt-2" />
		<EventList />
		<p class="text-center mt-2"><EventListNext /></p>
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import EventList from '@/components/EventList';
	import EventListFilter from '@/components/EventListFilter';
	import EventListNext from '@/components/EventListNext';

	export default {
		name: 'irrigator-profile-events-list',

		components: {
			EventList,
			EventListFilter,
			EventListNext,
		},

		computed: {
			...mapState('eventBookControl', ['events', 'eventTypes']),
			...mapState('irrigatorControl', ['irrigatorProfile']),

			eventList() {
				return this.events.map((item, index) => {
					return {
						item_number: index + 1,
						type: this.eventTypes.reduce(
							(acc, curr) =>
								curr.type == item.type ? curr.name : acc,
							''
						),
						area: item.association_area
							? item.association_area.name
							: '',
						subject: item.subject,
						class: this.eventTypes.reduce(
							(acc, curr) =>
								curr.type == item.type ? curr.class : acc,
							''
						),
						created: this.$utils
							.moment(item.createdAt)
							.format('DD/MM/YYYY HH:mm'),
						closed: item.closed,

						fulldata: item,
					};
				});
			},
		},

		mounted() {
			this.resetEventsLastVisible();
			this.loadEvents({
				next: 0,
				count: 0,
				irrigatorCode: this.irrigatorProfile.code,
			});
		},

		destroyed() {
			this.resetEventsLastVisible();
		},

		methods: {
			...mapActions('eventBookControl', [
				'loadEvents',
				'resetEventsLastVisible',
			]),
		},
	};
</script>
