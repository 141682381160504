<template>
	<v-container grid-list-xs>
		<v-row>
			<v-col class="text-center">
				<v-simple-table>
					<template v-slot:default>
						<thead>
							<tr>
								<th
									v-for="(item, index) in headers"
									:key="index"
									class="text-left"
								>
									{{ item.text }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in showUsers" :key="index">
								<td
									v-for="(field, index) in headers"
									:key="`${index}-tbody`"
									class="text-left"
								>
									<template v-if="item[field.value]">
										<template v-if="field.text == 'Nombre'">
											<a @click="selectWatchman(item)">{{
												item[field.value]
											}}</a>
										</template>

										<template v-else>
											{{ item[field.value] }}
										</template>
									</template>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { mapState, mapActions } from 'vuex';

	export default {
		name: 'watchman-list',
		data() {
			return {
				headers: [
					{ text: 'Nombre', value: 'name' },
					{ text: 'Email', value: 'email' },
					{ text: 'Teléfono', value: 'telephone' },
				],
			};
		},

		computed: {
			...mapState('userControl', ['watchmans']),

			showUsers() {
				const users = this.$utils.deepCopy(this.watchmans),
					list = users.map(u => {
						u.email = u.user && u.user.email ? u.user.email : '';
						return u;
					});

				return list;
			},
		},

		mounted() {
			this.loadWatchmanList();
		},

		methods: {
			...mapActions('userControl', ['loadWatchmanList']),

			selectWatchman(val) {
				this.$emit('watchman:selected', val);
			},
		},
	};
</script>
